<template>
  <v-app>

    <v-app-bar
      v-if="this.status"
      app
      color="white"
      elevation="1"
      ele
    >
      <v-app-bar-nav-icon @click="$store.commit('change_menu')"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <v-btn
        class="mr-4"
        depressed
        color="primary"
        tile
        @click="$router.push('/zgloszenie-nowe')"
        style="padding: 0 50px"
        v-if="$store.state.user_permissions.issue_create"
      >
        Zgłoś problem
      </v-btn>

      <v-badge
        color="primary"
        :content="notifications"
        :value="notifications"
        overlap
      >
        <v-btn
          icon
          color="indigo"
          small
          to="/powiadomienia"
        >
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </v-badge>

      <v-divider
        class="mx-2"
        vertical
      ></v-divider>

      <v-menu
        open-on-hover
        bottom
        offset-y
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
          >
            {{user_name}}
          </v-btn>
        </template>
        <v-list>

          <div>
          <v-btn
            text
            tile
            to="/ustawienia"
            style="width: 100%"
          >
            <span class="mr-2">Ustawienia</span>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-btn>
          </div>
          <div>
            <v-btn
              text
              tile
              @click="logout()"
              style="width: 100%;text-align: left"
            >
              <span class="mr-2">WYLOGUJ</span>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-btn>
          </div>

        </v-list>
      </v-menu>

    </v-app-bar>

    <v-main class="app_body">
      <!-- Wymuszenie czekania na pobranie permissionow -->
      <transition
        name="fade"
        mode="out-in"
        v-if="this.status"
      >
        <router-view/>
      </transition>
      <transition
        name="fade"
        mode="out-in"
        v-else
      >
        <Login />
      </transition>
    </v-main>

    <Menu
      v-if="this.status"
    />

    <v-snackbar
      :color="snackbar.color"
      v-model="snackbar.status"
      :bottom="snackbar.y === 'bottom'"
      :left="snackbar.x === 'left'"
      multi-line
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
      <v-btn
        :color="snackbar.btn_color"
        text
        @click="snackbar.status = false"
      >
        Zamknij
      </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-model="loader"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          <center>Przetwarzanie</center>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
// #TODO Faktura szczegoly - Nie ma mozliwosci pobrania faktury
// #TODO Naprawa menu mobilnym - trzeba kliknac 2x w hamburgera
// #TODO Wszedzie zrobic walidacje

import Menu from './components/Menu.vue';
import Login from './views/Login.vue';

export default {
  name: 'App',
  components: {
    Menu,
    Login,
  },
  data: () => ({
    snackbar: {
      status: false,
      x: 'right',
      y: 'top',
      timeout: 3000,
      text: '',
      color: '#333333',
      btn_color: 'pink'
    },

    // #TODO dodac zaczytywanie notyfikacji
  }),

  computed: {
    status() {
      return this.$store.state.isLogged;
    },
    loader() {
      return this.$store.state.loader;
    },
    user_name() {
      return this.$store.state.user_name;
    },
    notifications() {
      return this.$store.state.notifications;
    }
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  },
  watch:{
    // #TODO zrobic w pozniejszym okresie - modal wczytywania ma sie wysweitlac przed przejsciem na inna strone
    // $route (to, from){
    //   console.log(to);
    //   console.log(from);
    // }
  },

  mounted(){
    console.log('GLOBALNA');
    console.log(process.env.VUE_APP_API_URL);
    // console.log(this.$route.path)
    // if(localStorage.getItem('api') != undefined) this.$store.commit("change_api");

    this.$store.dispatch('check')
  },
  created() {
    this.$store.watch(state => state.snackbar.show, () => {
      if (this.$store.state.snackbar.show !== false) {
        this.snackbar.status = true
        this.snackbar.text = this.$store.state.snackbar.text;
        this.snackbar.color = this.$store.state.snackbar.color;
        this.snackbar.btn_color = this.$store.state.snackbar.btn_color;
        this.$store.commit('snackbar_off')
      }
    })
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>

<style lang="scss">
// Główny
.app_body {
  background-color: #f4f6f7;
}
// contener dla widokow
.home { 
  padding: 25px;
}
.section_title {
  margin-left: 15px;
  font-weight: 700;
  color: rgb(187, 187, 187);
}

// Style dla tabelek
.classic_table {
  tr {
    cursor: pointer;
  }
}

// Style dla widoków (najczesciej uzywane)
.card_action {
  margin: 0 25px 10px 25px;
  background: transparent !important;
}
.card_section {
  margin-top: 25px;
}
.inner_heading {
  color: black;
  padding-bottom: 8px;
  font-weight: 500;
}
.flex_end { // Do prawej (przyciski)
  align-items: center; 
  display: flex;
  justify-content: flex-end;
}

// Stryle VUE2-editor
.theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle {
  color: rgb(0,0,0) !important;
}
</style>