import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  //Dashboard, Panel
  {
    path: '/panel',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  //region[error] Klienci
  {
    path: '/klienci',
    name: 'Klienci',
    component: () => import('../views/Customers/All.vue')
  },
  { // Szczegóły klienta
    path: '/klient/zobacz/:id',
    name: 'Klient_zobacz',
    component: () => import('../views/Customers/Select.vue')
  },
  { // Edytuj klienta
    path: '/klient/edytuj/:id',
    name: 'Klient',
    component: () => import('../views/Customers/Edit.vue')
  },
  { // Nowy klient
    path: '/klient/nowy',
    name: 'Klient_nowy',
    component: () => import('../views/Customers/Add.vue')
  },
  // endregion
  // region [purple] Projekty
  {
    path: '/projekty',
    name: 'Projekty',
    component: () => import('../views/Projects/All.vue')
  },
  { // Dodaj nowy projekt
    path: '/projekt-nowy',
    name: 'Projekt_nowy',
    component: () => import('../views/Projects/Add.vue')
  },
  { // Zobacz pojedyncze
    path: '/projekt/:id',
    name: 'Projekt',
    component: () => import('../views/Projects/Select.vue')
  },
  { // Edytuj pojedynczy
    path: '/projekt-edytuj/:id',
    name: 'Projekt_edytuj',
    component: () => import('../views/Projects/Edit.vue')
  },
  { // Dodaj sprint do projektu
    path: '/projekt-sprint-nowy/:id',
    name: 'Projekt_nowy_sprint',
    component: () => import('../views/Projects/Add_sprint.vue')
  },
  { // Zobacz sprint
    path: '/sprint/:id',
    name: 'Projekt_sprint',
    component: () => import('../views/Projects/Sprint.vue')
  },
  { // Edytuj sprint
    path: '/sprint-edytuj/:id',
    name: 'Projekt_sprint_edytuj',
    component: () => import('../views/Projects/Edit_sprint.vue')
  },
  { // Dodaj task do sprinta
    path: '/task-nowy/:id',
    name: 'Projekt_task_nowy',
    component: () => import('../views/Projects/Add_task.vue')
  },
  // endregion
  // region [success] Koszty
  {
    path: '/koszty',
    name: 'Koszty',
    component: () => import('../views/Costs/All.vue')
  },
  {
    path: '/dodaj-nowy-koszt',
    name: 'Koszt_nowy',
    component: () => import('../views/Costs/Add.vue')
  },
  {
    path: '/dodaj-koszt/:id',
    name: 'Koszt_nowy_przypisany',
    component: () => import('../views/Costs/Add_selected.vue')
  },
  // endregion
  // region [error] Pakiety
  {
    path: '/pakiety',
    name: 'Pakiety',
    component: () => import('../views/Package/All.vue')
  },
  { // Zobacz kategorie pakietów
    path: '/pakiety-kategorie',
    name: 'Pakiety_kategories',
    component: () => import('../views/Package/Categories.vue')
  },
  { // Edytuj kategorie pakietu
    path: '/pakiet-kategoria/:id',
    name: 'Pakiety_kategories_edytuj',
    component: () => import('../views/Package/Categories_edit.vue')
  },
  { // Edytuj kategorie pakietu
    path: '/pakiet-kategoria-nowa',
    name: 'Pakiety_kategories_nowa',
    component: () => import('../views/Package/Categories_add.vue')
  },
  { // Wszystkie pakiety dla jednego klienta
    path: '/pakiet/:id',
    name: 'Pakiety_klient',
    component: () => import('../views/Package/Show.vue')
  },
  { // Dodaj nowy pakiet dla klienta
    path: '/pakiet-nowy/:id',
    name: 'Pakiety_klient_nowy',
    component: () => import('../views/Package/Add_new.vue')
  },
  { // Szczegóły pakietu dla klienta
    path: '/pakiet-zobacz/:id',
    name: 'Pakiet_klient_zobacz',
    component: () => import('../views/Package/Show_package.vue')
  },
  { // Edytuj pakiet dla klienta
    path: '/pakiet-edytuj/:id',
    name: 'Pakiety_klient_edytuj',
    component: () => import('../views/Package/Edit.vue')
  },
  // endregion
  // region [warning] Zgłoszenia
  { // Zobacz nowe zgłoszenie
    path: '/zgloszenie-nowe',
    name: 'Zgloszenia_nowe',
    component: () => import('../views/Issues/Add_new.vue')
  },
  { // Zobacz wszystkie zgloszenia
    path: '/zgloszenia',
    name: 'Zgloszenia',
    component: () => import('../views/Issues/All.vue')
  },
  { // Zobacz pojedyncze
    path: '/zgloszenie/zobacz/:id',
    name: 'Zgloszenie',
    component: () => import('../views/Issues/Select.vue')
  },
  // endregion
  //region[success] Faktury
  {
    path: '/faktury',
    name: 'Faktury',
    component: () => import('../views/Invoices/All.vue')
  },
  { // Pojedyncza faktua wyswietlanie
    path: '/faktura/zobacz/:id',
    name: 'Faktura',
    component: () => import('../views/Invoices/Select.vue')
  },
  { // Pojedyncza faktua wyswietlanie
    path: '/faktura/edytuj/:id',
    name: 'Faktura_edytuj',
    component: () => import('../views/Invoices/Edit.vue')
  },
  { // Dodaj fakturę
    path: '/faktura/nowa',
    name: 'Faktura_edytuj',
    component: () => import('../views/Invoices/Add.vue')
  },
  // endregion


  // region [purple] Użytkownicy
  {
    path: '/uzytkownicy',
    name: 'Uzytkownicy',
    component: () => import('../views/Users/All.vue')
  },
  { // Edycja użytkownika
    path: '/uzytkownik/:id',
    name: 'Uzytkownik',
    component: () => import('../views/Users/Edit.vue')
  },
  { // Dodawanie użytkownika
    path: '/uzytkownik-nowy',
    name: 'Uzytkownik_nowy',
    component: () => import('../views/Users/Add.vue')
  },
  // endregion
  // region [success] Role
  {
    path: '/role',
    name: 'Role',
    component: () => import('../views/Role/All.vue')
  },
  { // Edycja użytkownika
    path: '/rola/:id',
    name: 'Rola',
    component: () => import('../views/Role/Edit.vue')
  },
  { // Dodawanie użytkownika
    path: '/rola-nowa',
    name: 'Rola_nowa',
    component: () => import('../views/Role/Add.vue')
  },
  // endregion
  // region [purple] Podwykonawcy
  {
    path: '/podwykonawcy',
    name: 'Podwykonawcy',
    component: () => import('../views/Freelancers/All.vue')
  },
  { // Edycja użytkownika
    path: '/podwykonawca/:id',
    name: 'Uzytkownik',
    component: () => import('../views/Freelancers/Edit.vue')
  },
  { // Dodawanie użytkownika
    path: '/podwykonawca-nowy',
    name: 'Uzytkownik_nowy',
    component: () => import('../views/Freelancers/Add.vue')
  },
  // endregion
  // region [error] Baza wiedzy
  { // Wszystkie kategorie
    path: '/baza-wiedzy-lista',
    name: 'Baza_wiedzy_lista',
    component: () => import('../views/FAQ/All.vue')
  },
  { // Zobacz w kategorii
    path: '/baza-wiedzy/kategoria/:category',
    name: 'Baza_wiedzy_kategoria',
    component: () => import('../views/FAQ/Category.vue')
  },
  { // Edytuj kategorie
    path: '/baza-wiedzy/kategoria-edytuj/:category',
    name: 'Baza_wiedzy_kategoria_edytuj',
    component: () => import('../views/FAQ/Edit.vue')
  },
  { // Zobacz konkretną bazę wiedzy
    path: '/baza-wiedzy/zobacz/:id',
    name: 'Baza_wiedzy_zobacz_jeden',
    component: () => import('../views/FAQ/Details.vue')
  },
  { // Dodawanie kategorii
    path: '/baza-wiedzy-nowa',
    name: 'Baza_wiedzy_nowa',
    component: () => import('../views/FAQ/Add.vue')
  },
  { // Dodawanie strony w kategorii
    path: '/baza-wiedzy-nowa/:id',
    name: 'Baza_wiedzy_nowa_strona',
    component: () => import('../views/FAQ/Add_page.vue')
  },
  { // Edytowanie strony w kategorii
    path: '/baza-wiedzy/edytuj/:id',
    name: 'Baza_wiedzy_edytuj_strona',
    component: () => import('../views/FAQ/Edit_page.vue')
  },
  // endregion
  // region [purple] Kalendarz
  // {
  //   path: '/kalendarz',
  //   name: 'Kalendarz',
  //   component: () => import('../views/Calendar/Home.vue')
  // },
  // endregion
  // region [red] Notyfikacje
  {
    path: '/powiadomienia',
    name: 'Powiadomienia',
    component: () => import('../views/Notification/All.vue')
  },
  // endregion
  // region [success] Ustawienia
  {
    path: '/ustawienia',
    name: 'Ustawienia',
    component: () => import('../views/Settings/View.vue')
  },
  // endregion
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 500)
    })
  }
  
})

// router.beforeEach((to, from, next) => {
//   // Jeżeli nie ma pobranych uprawnien
//   if(!store.state.permissions.length) {
//     console.log('UZYTKOWNIK NIE MA POZWOLEN')
//   }
//   else store.dispatch('check_permission','role');
//   // console.log('CHUJ');
// })

export default router
