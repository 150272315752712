import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify';
import Axios from 'axios'

Vue.prototype.$axios = Axios;

Vue.config.productionTip = false

// Import komponentów
Vue.component('breadcrumb', 
    () => import('./components/breadcrumb.vue')
)
Vue.component('confirm_dialog', 
    () => import('./components/confirm_dialog.vue')
)

// Globalna funkcje
Vue.mixin({
  methods: {
    // do odwracania daty
    global_flipDate(date) {
      return date.split('-').reverse().join('-');
    },
    // do odczytania powiadomienia
    global_closeAlert(id) {
      let formData = new FormData();
      formData.append("notify[0][id]", id);
      this.$axios({url: this.$store.state.api +'/dash/notify/read', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
      .then(resp => {
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
    }
  },
})

// Import Vue2-editor
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
