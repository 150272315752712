<template>
  <div class="login_container">
    <v-row no-gutters>
      <v-col cols="12" md="6">
        
        <div class="login_card">
          <div class="inner">
            <v-card
              flat
              width="600px"
            >
              <v-img
                alt="Promoznawcy"
                contain
                src="../assets/promo_crm_color.png"
                transition="scale-transition"
                height="125px"
              ></v-img>
              <v-text-field
                v-model="user_login"
                label="Login"
                style="margin-top: 25px"
                @keydown.enter="login()"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="user_password"
                label="Hasło"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                @keydown.enter="login()"
                outlined
              ></v-text-field>
              <v-card-actions style="padding: 0">
                <v-btn 
                  @click="recovery_dialog=true"
                  color="secondary_dark"
                  text
                >Przypomnij hasło</v-btn>
                <v-spacer/>
                <v-btn 
                  @click="login()"
                  color="primary"
                  depressed
                >Zaloguj</v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </div>

      </v-col>
      <v-col cols="12" md="6">
        
        <v-img
          alt="Promoznawcy"
          class="img_container"
          cover
          src="../assets/promocrm.jpg"
          transition="scale-transition"
        >
          <svg class="draw_svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <polygon points="0,0 0,100 75,75"/>
          </svg> 
          <!-- <div class="logo">
            czemu
            <v-img class="logo_inner" src="../assets/promo_crm_color.png" contain style="margin: auto" height="85px" width="250px"></v-img>
          </div> -->
        </v-img>

      </v-col>
    </v-row>

    

    <v-dialog
      v-model="recovery_dialog"
      width="500"
      persistent
    >
      <v-card tile>
        <v-card-title class="change_card">
          Przypomnij hasło
        </v-card-title>

        <v-card-text class="mt-2">
          <v-form
            ref="reco" 
            v-model="recovery"
            lazy-validation
          >
            <v-text-field
              v-model="recovery_email"
              label="E-mail"
              style="margin-top: 25px"
              outlined
              :rules="$store.state.rules.not_null"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="recovery_dialog=false"
          >
            Anuluj
          </v-btn>
          <v-btn
            color="primary"
            tile
            depressed
            @click="recovery_password()"
          >
            Przypomnij hasło
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <v-dialog
      v-model="new_password_modal"
      width="500"
      persistent
    >
      <v-card tile>
        <v-card-title class="change_card">
          Wpisz nowe hasło
        </v-card-title>

        <v-card-text class="mt-2">
          <v-form
            ref="reco" 
            v-model="recovery"
            lazy-validation
          >
            <v-text-field
              v-model="new_password"
              label="Nowe hasło"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="new_password_repeat"
              label="Powtórz nowe hasło"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
              outlined
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="error"
            text
            @click="new_password_modal=false"
          >
            Anuluj
          </v-btn> -->
          <v-btn
            color="primary"
            tile
            depressed
            @click="try_change_password()"
          >
            Ustaw hasło
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  data: () => ({
    user_login: '',
    user_password: '',
    show: false,

    recovery: false,
    recovery_dialog: false,
    recovery_email: '',

    new_password_modal: false,
    new_password: '',
    new_password_repeat: '',

    // Przypisywanie wartosci z geta
    restore_data: {
      token: '',
      email: ''
    }
  }),
  methods: {
    login(){
      if(this.user_login.length && this.user_password.length){
        let prepared = {
          email: this.user_login,
          password: this.user_password
        };
        this.$store.dispatch('login', prepared);
      }
      else {
        this.$store.commit("snackbar", {
          text: "Proszę wypełnić pola",
          color: "error",
          btn_color: "white"
        });
      }
    },

    // Przypominanie hasła
    recovery_password(){
      if(this.$refs.reco.validate()) {
        this.$store.commit('loader');

        let formData = new FormData();
        formData.append("email", this.recovery_email);

        this.$axios({url: this.$store.state.api +'/auth/recovery', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
          .then(resp => {
            // this.$router.push('/role');
            this.$store.commit("snackbar", {
              text: "Proszę sprawdzić skrzynkę pocztową",
              color: "primary",
              btn_color: "white"
            });
            this.recovery_dialog = false;
          })
          .catch(err => {
            this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
          })
          .then (() => {
            this.$store.commit('loader_off');
          })
      }
    },

    // Próba ustawienia nowego hasła (Akceptacja modala)
    try_change_password(){
      if(this.new_password == this.new_password_repeat){
        this.new_password_modal = false;
        this.check_token('send_new')
      }
      else {
        this.$store.commit("snackbar", {
          text: "Hasła różnią się",
          color: "primary",
          btn_color: "white"
        });
      }
    },

    // Wysyłanie nowego hasła (uzytkownik wszedl w link z maila)
    send_new_password(){
      this.$store.commit('loader');

      let formData = new FormData();
      formData.append("token", this.restore_data.token);
      formData.append("email", this.restore_data.email);
      formData.append("password", this.new_password);

      this.$axios({url: this.$store.state.api +'/auth/reset', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          this.$store.commit("snackbar", {
            text: "Hasło zostało ustawione",
            color: "primary",
            btn_color: "white"
          });
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    },

    // Sprawdzanie tokena
    check_token(type){
      this.$store.commit('loader');
      this.$axios({url: this.$store.state.api +'/auth/check_token?token='+this.restore_data.token+'&email='+this.restore_data.email, data: {}, method: 'GET' })
        .then(resp => {
          // Aktywowanie konta - pozwala wyswietlic modal
          if(type == 'just_check'){
            this.new_password_modal = true;
          }
          // Przypomnienie hasla oraz aktywowanie konta - wysylanie na serwer nowych danych
          else if(type == 'send_new'){
            this.send_new_password();
          }
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    }

  },
  mounted(){
    if(localStorage.getItem('last_user')) this.user_login = localStorage.getItem('last_user');
    if(this.$store.state.isLogged) this.$router.push('panel');
    // this.$store.dispatch('check');

    // Wpisano token, chceszmy przywrocic haslo (recovery) - samo wyswietlanie 'wprowadz nowe haslo'
    if(this.$route.query.recovery && this.$route.query.email) {

      // Przypisywanie do zmiennych GETa
      this.restore_data.token = this.$route.query.recovery;
      this.restore_data.email = this.$route.query.email;

      this.check_token('just_check');
    }

    // Wysłano token aktywacyjny (activate)
    if(this.$route.query.activate && this.$route.query.email) {

      // Przypisywanie do zmiennych GETa
      this.restore_data.token = this.$route.query.activate;
      this.restore_data.email = this.$route.query.email;

      this.check_token('just_check');
    }
  }
};
</script>

<style lang="scss" scoped>
.login_container {
  background-color: white;
  .img_container {
    // position: relative;
    width: 100vw;
    height: 100vh;
  }
}
.logo {
  // width: 100%;
  // height: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  background-color: red;
}
.login_card {
  // position: absolute;
  padding: 0 25px;
  // margin-left: 70%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: end;
  .inner {
    width: 400px;
    max-width: 100%;
    margin-right: 150px;
    @media (max-width: 1160px) {
      margin-right: auto;
      margin-left: auto;
    }
  }
  .login_box {
    height: 100%;
    margin: 0;
  }
}

.draw_svg {
  position: relative;
  height: 100%;
  width: 400px;
  polygon {
    fill: white;
  }
  rect {
    fill:rgb(0,0,255);
    stroke-width:3;
    stroke:rgb(0,0,0);
    height: 100vh;
    width: 100px;
  }
}
</style>