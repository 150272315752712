<template>
  
  <v-navigation-drawer
      app 
      :value="menu"
      color="#333"
    >

      <div class="menu_container">
        <!-- <v-btn
          color="pink"
          depressed
          fab
          small
          fixed
          top
          right
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn> -->

        <div>

          <div style="background-color:#b0cb1f">
            <v-img src="../assets/promo_crm.png" contain style="margin: auto" height="64px" width="200px"></v-img>
          </div>

          <v-divider></v-divider>


          <v-list
            dense
            nav
            dark
          >
            <v-list-item
              link
              :to="'/panel'"
            >
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Pulpit</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <!-- <span class="section_title">KONTRAHENCI</span> -->
          <hr>

          <v-list
            dense
            nav
            dark
          >
            <v-list-item
              v-for="item in list_menu1"
              :key="item.to"
              link
              :to="item.to"
            >
              <v-list-item-icon>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{item.name}}</v-list-item-title>
              </v-list-item-content>

            </v-list-item>
          </v-list>

          <!-- <span class="section_title">WEWNĘTRZNE</span> -->
          <hr>

          <v-list
            dense
            nav
            dark
          >
            <v-list-item
              v-for="item in list_menu2"
              :key="item.to"
              link
              :to="item.to"
            >
              <v-list-item-icon>  
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{item.name}}</v-list-item-title>
              </v-list-item-content>

            </v-list-item>
          </v-list>
        </div>

        <div class="version">
         <a href="https://promocrm.pl">PROMOCRM</a> | v0.18 Alpha
        </div>
      </div>

    </v-navigation-drawer>

</template>

<script>
  export default {
    name: 'Menu',

    data: () => ({
    list_menu1: [
      {
        icon: 'mdi-account',
        to: '/klienci',
        name: 'Klienci',
        permission_slug: ['customer']
        // #TODO Nie wiadomo jaki jest prawidlowy link
      },
      {
        icon: 'mdi-book',
        to: '/projekty',
        name: 'Projekty',
        permission_slug: ['project.all','project.select']
      },
      {
        icon: 'mdi-bookmark-check',
        to: '/pakiety',
        name: 'Pakiety',
        permission_slug: ['package.all','package.select']
      },
      {
        icon: 'mdi-book-outline',
        to: '/faktury',
        name: 'Faktury',
        permission_slug: ['invoice.all','invoice.select']
      },
      {
        icon: 'mdi-message-bookmark',
        to: '/zgloszenia',
        name: 'Zgłoszenia',
        permission_slug: ['issue.all','issue.select']
      },
      {
        icon: 'mdi-cash-multiple',
        to: '/koszty',
        name: 'Koszty',
        permission_slug: ['project_cost.all']
      }
    ],

    list_menu2: [
      // {
      //   icon: 'mdi-calendar',
      //   to: '/kalendarz',
      //   name: 'Kalendarz',
      //   permission_slug: ['user']
      // },
      {
        icon: 'mdi-account',
        to: '/uzytkownicy',
        name: 'Użytkownicy',
        permission_slug: ['user']
      },
      {
        icon: 'mdi-account-group-outline',
        to: '/role',
        name: 'Role',
        permission_slug: ['role']
      },
      {
        icon: 'mdi-account-child',
        to: '/podwykonawcy',
        name: 'Podwykonawcy',
        permission_slug: ['user']
      },
      // {
      //   icon: 'mdi-book-open-blank-variant',
      //   to: '/baza-wiedzy-lista',
      //   name: 'Baza wiedzy',
      //   permission_slug: ['category_faq.all','category_faq.select']
      // },
    ] 
    }),
    computed: {
      menu() {
        return this.$store.state.show_menu;
      },
    },

    methods: {
      prepare_menu() {
        let permissions = this.$store.state.permissions;
        // let permissions = [
        //   { id: 1, name: 'issue.all', slug: 'issue.select'},
        //   { id: 2, name: 'test', slug: 'customer'},
        // ];

        // Stara lista do tworzenia pierwszego menu
        let prepared_menu = [];
        for(let x=0;x<this.list_menu1.length;x++){
          for(let i=0;i<permissions.length;i++){
            for(let z=0;z<this.list_menu1[x].permission_slug.length;z++){
              if(this.list_menu1[x].permission_slug[z] == permissions[i].slug){
                prepared_menu.push(this.list_menu1[x]);
                // Zamykanie poprzeniej petli
                i++;
                break;
              } 
            }
          }
        }
        this.list_menu1 = prepared_menu;

        // Stara lista do tworzenia drugiego menu
        prepared_menu = [];
        for(let x=0;x<this.list_menu2.length;x++){
          for(let i=0;i<permissions.length;i++){
            for(let z=0;z<this.list_menu2[x].permission_slug.length;z++){
              if(this.list_menu2[x].permission_slug[z] == permissions[i].slug){
                prepared_menu.push(this.list_menu2[x]);
                // Zamykanie poprzeniej petli
                i++;
                break;
              } 
            }
          }
        }
        this.list_menu2 = prepared_menu;
      }
    },
    mounted(){
      this.prepare_menu();
    }
  }
</script>
<style lang="scss" scoped>
$color: rgb(139, 139, 139);
.menu_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .version {
    text-align: center;
    // background-color: #b0cb1f;
    border-top: 1px solid $color;
    width: 90%;
    margin: 0 auto;
    color: $color;
    padding: 2px 0 12px 0;
    font-size: .7rem;
    a {
      text-decoration: none;
      color: $color;
    }
  }
}
</style>