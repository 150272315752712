import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // api: 'https://api-pm.test.xantro.pl/api',
    // api: 'https://api-crm.promoznawcy.pl/api',
    api: process.env.VUE_APP_API_URL,
    isLogged : false,
    loggedUserRole: 0,
    // 0 - admin
    // 1 - user - pracownik
    // 2 - freelancer - podwykonawca
    // 3 - klient
    loader: false,
    snackbar: {
      show: false,
      text: '',
      color: '',
      btn_color: '',
    },
    permissions: [],
    user_permissions: [], // Ten nowy, wyzej usunac
    show_menu: true,
    user_name: 'Użytkownik',
    notifications: 0,

    rules: {
      not_null: [v => !!v || 'Pole jest wymagane']
    }
  },
  mutations: {
    login( state ){
      state.isLogged = true;
    },
    logout( state ){
      state.isLogged = false;
    },
    wrong_form_snackbar( state ){
      state.snackbar.show = true;
      state.snackbar.text = 'Sprawdz poprawnosc formularza';
      state.snackbar.color = 'error';
      state.snackbar.btn_color = 'white';
    },
    snackbar( state , data ){
      state.snackbar.show = true;
      state.snackbar.text = data.text;
      state.snackbar.color = data.color;
      state.snackbar.btn_color = data.btn_color;
    },
    snackbar_off ( state ){
      state.snackbar.show = false;
      state.snackbar.text = '';
      state.snackbar.color = '';
      state.snackbar.btn_color = '';
    },
    loader( state ){
      state.loader = true;
    },
    loader_off( state ){
      state.loader = false;
    },
    // Przypisywanie uprawnien
    set_permissions( state, data ){
      state.permissions = data; // Wyjebac pozniej po obrobieniu MENU

      for(let i=0;i<data.length;i++){
        switch(data[i].slug){
          // #region[success] Tworzenie MENU
          // Wyswietlanie klientow
          case 'customer':
            state.user_permissions.menu_customers = true;
            break;
          // Wyswietlanie Projektow
          case 'project.all':
            state.user_permissions.menu_projects = true;
            break;
          case 'project.select':
            state.user_permissions.menu_projects = true;
            break;
          // Wyswietlanie pakietow
          case 'package.all':
            state.user_permissions.menu_packages = true;
            break;
          case 'package.select':
            state.user_permissions.menu_packages = true;
            break;
          // Wyswietlanie Faktur
          case 'invoice.all':
            state.user_permissions.menu_invoices = true;
            break;
          case 'invoice.select':
            state.user_permissions.menu_invoices = true;
            break;
          // Wyswietlanie Zgłoszeń
          case 'issue.all':
            state.user_permissions.menu_issues = true;
            break;
          case 'issue.select':
            state.user_permissions.menu_issues = true;
            break;
          // Wyswietlanie Kosztów
          case 'project_cost.all':
            state.user_permissions.menu_costs = true;
            break;
          // Wyswietlanie Użytkowników/Pracowników oraz Podwykonawców/Freelancerow
          case 'user':
            state.user_permissions.menu_users = true;
            break;
          // Wyswietlanie Ról
          case 'role':
            state.user_permissions.menu_roles = true;
            break;
          // Wyswietlanie Ról
          case 'role':
            state.user_permissions.menu_roles = true;
            break;
          // #endregion

          // #region[warning] Projekty
          // Mozliwosc wyświetlania zamknietych projektow
          case 'project.archive':
            state.user_permissions.project_archive = true;
            break;
          // Mozliwosc dodania projektu
          case 'project.create':
            state.user_permissions.project_create = true;
            break;
          // Możliwość wejścia do projektu
          case 'project.show':
            state.user_permissions.project_show = true;
            break;
          // Edycja projektu
          case 'project.update':
            state.user_permissions.project_update = true;
            break;
          // Usuwanie projektu
          case 'project.delete':
            state.user_permissions.project_delete = true;
            break;
          // Wyswietlanie etapow - wszystkich
          case 'sprint.all':
            state.user_permissions.project_sprints = true;
            break;
          // Wyswietlanie etapow - tylko przypisanych / dzilanie to samo
          case 'sprint.select':
            state.user_permissions.project_sprints = true;
            break;
          // Mozliwosc wejscia w etap
          case 'sprint.show':
            state.user_permissions.project_sprint_show = true;
            break;
          // Mozliwosc dodawania sprintu
          case 'sprint.create':
            state.user_permissions.project_sprint_create = true;
            break;
          // Mozliwosc edytowania sprintu
          case 'sprint.update':
            state.user_permissions.project_sprint_update = true;
            break;
          // Mozliwosc usuniecia sprintu
          case 'sprint.delete':
            state.user_permissions.project_sprint_delete = true;
            break;
          // Mozliwosc dodania zadania
          case 'task.create':
            state.user_permissions.project_task_create = true;
            break;
          // Mozliwosc edytowania zadania
          case 'task.update':
            state.user_permissions.project_task_update = true;
            break;
          // Mozliwosc usuniecia zadania
          case 'task.delete':
            state.user_permissions.project_task_delete = true;
            break;
          // #endregion
          // #region[primary] Koszty
          // Możliwość dodawania kosztu
          case 'project_cost.create':
            state.user_permissions.project_cost_create = true;
            break;
          // Możliwość edytowania kosztów
          case 'project_cost.update':
            state.user_permissions.project_cost_update = true;
            break;
          // Możliwość usuwania kosztów
          case 'project_cost.destroy':
            state.user_permissions.project_cost_destroy = true;
            break;
          // #endregion
          // #region[purple] Faktury
          // Mozliwosc stworzenia faktury
          case 'invoice.create':
            state.user_permissions.invoice_create = true;
            break;
          // Mozliwosc otworzenia faktury
          case 'invoice.show':
            state.user_permissions.invoice_show = true;
            break;
          // Mozliwosc edycji faktury
          case 'invoice.update':
            state.user_permissions.invoice_update = true;
            break;
          // Mozliwosc usuwania faktury
          case 'invoice.delete':
            state.user_permissions.invoice_delete = true;
            break;
          // #endregion
          // #region[success] Zgloszenia
          // Mozliwosc dodawania zgloszenia
          case 'issue.create':
            state.user_permissions.issue_create = true;
            break;
          // Mozliwosc wyswietlania zgloszenia
          case 'issue.show':
            state.user_permissions.issue_show = true;
            break;
          // Mozliwosc edytowania zgloszenia
          case 'issue.update':
            state.user_permissions.issue_update = true;
            break;
          // Mozliwosc dodania komentarza
          case 'issue_comment.create':
            state.user_permissions.issue_comment_create = true;
            break;
          // #endregion
          // #region[info] Pakiety
          // Mozliwosc zarzadzania kategoriami pakietow
          case 'package.category':
            state.user_permissions.package_category = true;
            break;
          // Mozliwosc dodawania pakietu
          case 'package.create':
            state.user_permissions.package_create = true;
            break;
          // Mozliwosc wyswietlenia pakietu
          case 'package.show':
            state.user_permissions.package_show = true;
            break;
          // Mozliwosc edycji pakietu
          case 'package.update':
            state.user_permissions.package_update = true;
            break;
          // Mozliwosc usuniecia pakietu
          case 'package.delete':
            state.user_permissions.package_delete = true;
            break;
          // #endregion
          // #region[error] Role - Znajdywanie jaka role posiada user
          case 'dashboard.admin':
            state.loggedUserRole = 0;
            break;
          case 'dashboard.employee':
            state.loggedUserRole = 1;
            break;
          case 'dashboard.freelancer':
            state.loggedUserRole = 2;
            break;
          case 'dashboard.client':
            state.loggedUserRole = 3;
          // #endregion
        }
      }
    },
    change_menu( state ){
      state.show_menu = !state.show_menu;
    },
    change_user( state ){
      state.user_name = localStorage.getItem('user')
    }
  },
  actions: {
    // check_permission( {state}, data){
    // #TODO dodac zabezpiecznie formularza (wszystkie pola wymagane, dodane tylko form)
    //   // #TODO na testy - wycofane - uzytkownik wejdzie na jaki chce routs, brak dostepu bedzie oparty na responsie 
    //   let user_have_permission = false;
    //   console.log(state.permissions);
    //   for(let num=0;num<state.permissions.length;num++){
    //     if(state.permissions[num].slug==data) {
    //       user_have_permission = true;
    //       break;
    //     }
    //   }
    //   console.log('data');
    //   console.log(data);
    //   if(user_have_permission) console.log('Moze wejsc');
    //   else console.log('nie moze wejsc');
    // },
    login({commit, state}, user){
      commit('loader')

        axios({url: state.api +'/auth/login', data: user, method: 'POST' })
          .then(resp => {

            const token = 'Bearer ' + resp.data.access_token
            const expires = resp.data.expires_in;

            localStorage.setItem('token', token)

            let date = new Date().getTime() /1000 + expires;
            localStorage.setItem('tokenExpires', date)

                axios.defaults.headers.common['Authorization'] = token

            // Przypisywanie nazwy uzytkownika
            localStorage.setItem('user',user.email)
            commit('change_user');

            // Zapisywanie nazwy uzytkownika w localstorage dla szybszego logowania
            localStorage.setItem('last_user',user.email)

            // Ustawianie uprawninen globalnie
            commit('set_permissions', resp.data.permissions);

            commit('login')

            router.push('/panel');
            
            commit('loader_off')

            })
            .catch(err => {
              commit('loader_off')
                commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
              // localStorage.removeItem('token')
               
            })
    },
    setAuthorization(){
      axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
    },
    get_menu({dispatch,commit,state}){ // Pobieranie uprawnień
      axios({url: state.api +'/auth/permission', data: {}, method: 'GET' })
        .then( resp => {
          commit('set_permissions', resp.data);
          // dispatch('check_permission',window.location.pathname);
          commit('login');
        })
        .catch(err => {
          commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
          reject(err)
      });
    },
    check({dispatch, commit, state}){
      if(!state.isLogged){ // Jezeli nie jest zalogowany
        if(localStorage.getItem('token') != null) { //istnieje token w local store
          // Przypisywanie nazwy u zywtkownika
          commit('change_user')
          let timeNow = new Date().getTime() /1000;
          axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
          if(timeNow >= localStorage.getItem('tokenExpires')) { // token wygasl
            commit('logout')
            localStorage.removeItem('token')
            localStorage.removeItem('tokenExpires')
                // console.log('usuwanie tokena; ELSE1')
                delete axios.defaults.headers.common['Authorization']

            // router.push('/');
            commit('snackbar', {text: 'Sesja nieaktywna. Proszę się zalogować.', color: 'error', btn_color: 'black'})
          }
          else if( timeNow + 300 > localStorage.getItem('tokenExpires') ){ // zostalo mniej niz 5 minut (refresh tokena)
            dispatch('get_menu');
            dispatch('refresh')
            if(window.location.pathname == '/') router.push('/panel');
          }
          else {
            dispatch('get_menu');
            let leftTime = ((localStorage.getItem('tokenExpires') - timeNow)/60).toFixed(0);
            commit('snackbar', {text: 'Do końca sesji: '+leftTime+ ' min.' , color: 'primary', btn_color: 'white'})
            if(window.location.pathname == '/') router.push('/panel');
          }
        }
        else {
          // Użytkownik niezalogowany
          router.push('/');
          commit('logout')
          // commit('snackbar', {text: 'Sesja nieaktywna. Proszę się zalogować.', color: 'error', btn_color: 'black'})
        }
      }
      else { // [zalogowany] Sprawdzanie czy token nie wygasl
        let timeNow = new Date().getTime() /1000;
        if(timeNow >= localStorage.getItem('tokenExpires')) { // token wygasl
          commit('logout')
            localStorage.removeItem('token')
            localStorage.removeItem('tokenExpires')

                // console.log('usuwanie tokena; ELSE2')
                delete axios.defaults.headers.common['Authorization']

            // router.push('/');
            // commit('logout')
            commit('snackbar', {text: 'Sesja wygasła.', color: 'error', btn_color: 'black'})
        }
        else if ( timeNow + 1500 > localStorage.getItem('tokenExpires') ) { //zostalo mniej niz 15 minut (refresh tokena)
          dispatch('refresh')
        }
      }                
    },
    refresh({commit, state}){
      return new Promise((resolve, reject) => {
        // console.log('refresh');
        axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        axios({url: state.api +'/auth/refresh', data: {}, method: 'POST' })
          .then(resp => {
            
            localStorage.removeItem('token')
            localStorage.removeItem('tokenExpires')
            
            const token = 'Bearer ' + resp.data.token
            const expires = resp.data.expires_in;
            
            localStorage.setItem('token', token)
            
            let date = new Date().getTime() /1000 + expires;
            localStorage.setItem('tokenExpires', date)
            
            axios.defaults.headers.common['Authorization'] = token

            commit('snackbar', {text: 'Odświeżono sesję.', color: 'warning', btn_color: 'black'})
            // commit('snackbar', {text: 'Odświeżono sesje ponieważ do jej zakończenia pozostało mniej niz 15 min', color: 'primary', btn_color: '#333'})
          })
            .catch(err => {
                commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
              localStorage.removeItem('token')
                reject(err)
            })
        })
    },
    logout({commit, state}){
      commit('loader');
      return new Promise((resolve) => {
        axios({url: state.api +'/auth/logout', data: {}, method: 'POST' })
          .then (() => {
            localStorage.removeItem('token')
            localStorage.removeItem('tokenExpires')
            localStorage.removeItem('user')
            commit('snackbar', {text: 'Wylogowano pomyślnie', color: 'primary', btn_color: '#333'})
            router.push('/');
            delete axios.defaults.headers.common['Authorization']
            resolve()
            commit('logout')
            commit('loader_off')
          })
      })
    },
    // Pobieranie pliku z serwera
    download_file({commit, state}, data){
      commit('loader');
      axios({url: state.api +'/file/' + data.id, data: {}, method: 'GET', responseType: 'blob' })
        .then (resp => {
          let url = URL.createObjectURL(resp.data)
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', data.name);
          document.body.appendChild(link);
          link.click();

          commit('loader_off')
        })
    },
  },
  modules: {
  }
})
